import { memo, useEffect, useState } from "react";
import { ShaderGradientCanvas, ShaderGradient } from "shadergradient";
import throttle from "lodash/throttle";
import clamp from "lodash/clamp";
import { useTransform, motion, useScroll } from "framer-motion";
import * as reactSpring from "@react-spring/three";
import * as drei from "@react-three/drei";
import * as fiber from "@react-three/fiber";

const mapScrollToRange = (
  scrollY: number,
  scrollMin: number,
  scrollMax: number,
  min: number,
  max: number
): number => {
  // Linearly maps the scroll position to the desired range
  const mappedValue =
    ((max - min) * (scrollY - scrollMin)) / (scrollMax - scrollMin) + min;
  // Clamps the value to ensure it stays within the specified range
  const clampedValue = clamp(mappedValue, min, max);
  return clampedValue;
};

enum CDistanceRange {
  MIN = 2.8,
  MAX = 20.8,
}
enum CPolarAngleRange {
  MIN = 80,
  MAX = 150,
}
enum CAzimuthAngleRange {
  INITIAL = 0,
  MIN = 180,
  MAX = 360,
}

interface BackgroundShaderProps {
  zoomOut?: boolean;
}
export const BackgroundShader = memo((props: BackgroundShaderProps) => {
  const { zoomOut = false } = props;

  const [cDistance, setCDistance] = useState(CDistanceRange.MIN);
  const [cPolarAngle, setCPolarAngle] = useState(CPolarAngleRange.MIN);
  const [cAzimuthAngle, setCAzimuthAngle] = useState(
    CAzimuthAngleRange.INITIAL
  );
  const { scrollYProgress } = useScroll();
  const scrollYOpacity = useTransform(scrollYProgress, [0, 1], [0.7, 0.3]);

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const docHeight = document.documentElement.scrollHeight;
    const maxScrollValue = docHeight - windowHeight;

    const handleScroll = throttle(() => {
      requestAnimationFrame(() => {
        const clampedScrollY = mapScrollToRange(
          window.scrollY,
          0,
          maxScrollValue,
          CDistanceRange.MIN,
          CDistanceRange.MAX
        );
        setCDistance((prevCDistance) => {
          if (prevCDistance !== clampedScrollY) {
            return clampedScrollY;
          }
          return prevCDistance;
        });
      });
    }, 100);

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      handleScroll.cancel();
    };
  }, []);

  useEffect(() => {
    // Mouse move effect
    const handleMouseMove = throttle((event: MouseEvent) => {
      requestAnimationFrame(() => {
        const { clientX, clientY } = event;
        const width = window.innerWidth;
        const height = window.innerHeight;

        // Calculate and clamp azimuth angle based on cursor's x position
        const azimuth = clamp(
          (clientX / width) * 360,
          CAzimuthAngleRange.MIN,
          CAzimuthAngleRange.MAX
        );
        // Calculate and clamp polar angle based on cursor's y position
        const polar = clamp(
          (clientY / height) * 180,
          CPolarAngleRange.MIN,
          CPolarAngleRange.MAX
        );

        setCAzimuthAngle(azimuth);
        setCPolarAngle(polar);
      });
    }, 100);

    window.addEventListener("mousemove", handleMouseMove, { passive: true });
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      handleMouseMove.cancel();
    };
  }, []);

  const shouldChangeAngles = cDistance === CDistanceRange.MAX;

  return (
    <>
      <ShaderGradientCanvas
        pointerEvents="none"
        importedFiber={{ ...fiber, ...drei, ...reactSpring }}
        style={{
          position: "fixed",
          top: 0,
          zIndex: -1,
          pointerEvents: "none",
        }}
      >
        <ShaderGradient
          animate="on"
          zoomOut={zoomOut}
          brightness={1}
          cAzimuthAngle={
            shouldChangeAngles ? cAzimuthAngle : CAzimuthAngleRange.MIN
          }
          cDistance={cDistance}
          cPolarAngle={shouldChangeAngles ? cPolarAngle : CPolarAngleRange.MIN}
          cameraZoom={9.1}
          color1="#606080"
          color2="#8d7dca"
          color3="#212121"
          envPreset="city"
          frameRate={60}
          grain="on"
          lightType="3d"
          range="enabled"
          rangeEnd={40}
          rangeStart={0}
          reflection={0.1}
          rotationX={50}
          rotationZ={-60}
          shader="defaults"
          type="waterPlane"
          uAmplitude={0}
          uDensity={1.5}
          uFrequency={0}
          uSpeed={0.3}
          uStrength={1.5}
          uTime={8}
          wireframe={false}
          control="props"
        />
      </ShaderGradientCanvas>

      <motion.div
        className="fixed top-0 left-0 w-full h-full bg-slate-950"
        style={{ opacity: scrollYOpacity }}
      ></motion.div>
    </>
  );
});
